<template>
  <div class="container mx-auto bg-center">
    <div class="container mx-auto overflow-hidden bg-center">
      <div class="mx-auto flex lg:h-[750px] lg:w-[1440px] flex-col relative">
        <img
          loading="lazy"
          v-if="currentImage"
          class="w-[1440px]"
          :src="currentImage"
          alt=""
        />
        <div class="absolute top-2/4 w-full" v-if="images.length > 1">
          <div class="flex justify-between">
            <font-awesome-icon
              class="text-white p-3 rounded-3xl bg-black mx-3 ltr:hidden"
              @click="prevImage"
              :icon="['fas', 'arrow-right']"
            />
            <font-awesome-icon
              class="text-white p-3 rounded-3xl bg-black mx-3 rtl:hidden"
              @click="prevImage"
              :icon="['fas', 'arrow-left']"
            />
            <font-awesome-icon
              class="text-white p-3 rounded-3xl bg-black mx-3 ltr:hidden"
              @click="nextImage"
              :icon="['fas', 'arrow-left']"
            />
            <font-awesome-icon
              class="text-white p-3 rounded-3xl bg-black mx-3 rtl:hidden"
              @click="nextImage"
              :icon="['fas', 'arrow-right']"
            />
          </div>
        </div>
        <div
          class="flex justify-center absolute top-[90%] -translate-x-2/4 left-2/4"
        >
          <font-awesome-icon
            v-for="(image, i) in images"
            :key="i"
            :class="{
              'text-blue-400': currentImageIndex === i,
              'text-gray-500': currentImageIndex !== i,
            }"
            :icon="['fas', 'circle']"
            class="mx-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageCarousel",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentImageIndex: 0,
      interval: null,
    };
  },
  computed: {
    currentImage() {
      return this.images.length ? this.images[this.currentImageIndex] : null;
    },
  },
  methods: {
    nextImage() {
      if (this.currentImageIndex < this.images.length - 1) {
        this.currentImageIndex++;
      } else {
        this.currentImageIndex = 0;
      }
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      } else {
        this.currentImageIndex = this.images.length - 1;
      }
    },
    startAutoSlide() {
      this.interval = setInterval(() => {
        this.nextImage();
      }, 15000);
    },
    stopAutoSlide() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
};
</script>

<style scoped>
.mean img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
</style>
