<template>
  <div>
    <div
      class="h-[90vh] relative bg-cover bg-center overflow-hidden bg-current bg-no-repeat before:absolute before:bg-opacity-55 before:top-0 before:left-0 before:bg-black before:z-10 before:w-full before:h-full"
      :style="{ backgroundImage: `url(${serviceImage})` }"
    >
      <div
        class="w-full h-full flex justify-center items-end relative cursor-pointer"
      >
        <font-awesome-icon
          class="text-red-700 absolute z-30 text-3xl mb-8 transition-all duration-1000"
          :class="bottomClass"
          :icon="['fas', 'angles-down']"
          @click="scrollToNext"
        />
      </div>
    </div>
    <div ref="nextSection" class="container mx-auto">
      <h2 class="text-3xl text-red-500 py-4">{{ serviceTitle }}</h2>
      <p>{{ serviceDesc }}</p>
      <div class="py-4">
        <ul>
          <li v-for="index in steps" :key="index" class="pb-4">
            <div v-if="hasDetail1(index)" class="text-red-500 text-xl pb-2">
              {{ $t(`our_service.${id}.step_${index}_title`) }}
            </div>
            <p v-if="hasDetail2(index)">
              {{ $t(`our_service.${id}.step_${index}_description`) }}
            </p>
            <ul>
              <li v-if="hasDetail(index)">
                {{ $t(`our_service.${id}.step_${index}_detail_1`) }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceDetail",
  props: ["id"],
  computed: {
    serviceTitle() {
      return this.$t(`our_service.service_title_${this.id}`);
    },
    serviceDesc() {
      return this.$t(`our_service.service_desc_${this.id}`);
    },
    serviceImage() {
      return require(`../assets/Images/service/${this.id}.jpg`);
    },
  },
  methods: {
    moving() {
      this.bottomClass =
        this.bottomClass === "bottom-4" ? "bottom-0" : "bottom-4";
    },
    scrollToNext() {
      this.$refs.nextSection.scrollIntoView({ behavior: "smooth" });
    },
    hasDetail(index) {
      const detail = this.$t(`our_service.${this.id}.step_${index}_detail_1`);
      return detail && detail.length > 50;
    },
    hasDetail2(index) {
      const detail = this.$t(
        `our_service.${this.id}.step_${index}_description`
      );
      return detail && detail.length > 38;
    },
    hasDetail1(index) {
      const detail = this.$t(`our_service.${this.id}.step_${index}_title`);
      if (detail.length == 26) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.moving();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  data() {
    return {
      bottomClass: "bottom-4",
      steps: [1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
};
</script>
