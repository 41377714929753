import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import i18n from "./i18n.js";
import ar from "./locales/ar.json";
import en from "./locales/en.json";
import MainButton from "./components/MainButton.vue";
import HeaderNav from "./components/HeaderNav.vue";
import FooterBar from "./components/FooterBar.vue";
import HeadSection from "./components/HeadSection.vue";
import MainButtonBlack from "./components/MainButtonBlack.vue";
import OurServices from "./components/OurServices.vue";
import SlidSec from "./components/SlidSec.vue";
import VueScrollTo from "vue-scrollto";

// Import fontawesome core and icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAnglesUp,
  faLocationDot,
  faGlobe,
  faArrowRight,
  faArrowLeft,
  faEye,
  faBullseye,
  faEnvelope,
  faEllipsis,
  faEllipsisVertical,
  faLink,
  faPhone,
  faClock,
  faChevronRight,
  faChevronLeft,
  faAnglesDown,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvira } from "@fortawesome/free-brands-svg-icons";
library.add(
  faGlobe,
  faAnglesUp,
  faLocationDot,
  faArrowRight,
  faArrowLeft,
  faEnvira,
  faEye,
  faBullseye,
  faEnvelope,
  faEllipsis,
  faEllipsisVertical,
  faLink,
  faPhone,
  faClock,
  faLocationDot,
  faChevronRight,
  faChevronLeft,
  faAnglesDown
);

const app = createApp(App);

app.component("SlidSec", SlidSec);
app.component("MainButtonBlack", MainButtonBlack);
app.component("HeadSection", HeadSection);
app.component("MainButton", MainButton);
app.component("HeaderNav", HeaderNav);
app.component("FooterBar", FooterBar);
app.component("OurServices", OurServices);
app.component("font-awesome-icon", FontAwesomeIcon);

app.use(store);
app.use(VueScrollTo);
app.use(i18n);
app.use(ar);
app.use(en);
app.use(router);

app.mount("#app");
