<template>
  <div>
    <nav class="max_md:relative bg-gray-50">
      <div
        class="container mx-auto flex justify-between items-center py-2 z-60"
      >
        <router-link to="/" class="lg:w-80 md:w-64 w-32">
          <img class="rtl:hidden" src="../assets/logov.png" alt="" />
          <img class="ltr:hidden" src="../assets/arlogo.png" alt="" />
        </router-link>
        <div class="md:hidden" @click="toggleMenu">
          <font-awesome-icon class="duration-500" :icon="menuIcon" />
        </div>
        <div
          class="max_md:absolute max_md:w-full max_md:h-screen left-0 duration-500 z-50"
          :class="{
            'top-full': isMenuVisible,
            '-top-[200vh]': !isMenuVisible,
            'max_md:bg-white': true,
          }"
        >
          <ul class="md:flex max_md:flex-col max_md:divide-y-2">
            <li class="mx-4 max_md:py-4">
              <router-link
                to="/"
                @click="hideMenu"
                class="capitalize"
                active-class="active-link"
              >
                {{ $t("nav.home") }}
              </router-link>
            </li>
            <li class="mx-4 max_md:py-4">
              <router-link
                to="/About"
                @click="hideMenu"
                class="capitalize"
                active-class="active-link"
              >
                {{ $t("nav.services") }}
              </router-link>
            </li>
            <li class="mx-4 max_md:py-4">
              <router-link
                to="/QHSEPolicy"
                @click="hideMenu"
                class="capitalize"
                active-class="active-link"
              >
                {{ $t("nav.Our_Policies") }}
              </router-link>
            </li>
            <li class="mx-4 max_md:py-4">
              <router-link
                to="/NewsAndTopic"
                @click="hideMenu"
                class="capitalize"
                active-class="active-link"
              >
                {{ $t("nav.news") }}
              </router-link>
            </li>
            <li class="mx-4 max_md:py-4">
              <router-link
                :to="{ name: 'IsoDetail', params: { id: 10 } }"
                @click="hideMenu"
                class="capitalize"
                active-class="active-link"
              >
                {{ $t("nav.sustainable_development") }}
              </router-link>
            </li>
            <li class="mx-4 max_md:py-4">
              <router-link
                to="/contact"
                @click="hideMenu"
                class="capitalize"
                active-class="active-link"
              >
                {{ $t("nav.contact_us") }}
              </router-link>
            </li>
            <li>
              <router-link to="/" @click="changeLang">
                <font-awesome-icon
                  class="hover:text-red-500 max_md:px-4 max_md:py-2"
                  :icon="['fas', 'globe']"
                />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      locale: this.$i18n.locale,
      isMenuVisible: false,
      lastScrollPosition: 0,
    };
  },
  computed: {
    menuIcon() {
      return this.isMenuVisible
        ? ["fas", "ellipsis"]
        : ["fas", "ellipsis-vertical"];
    },
  },
  methods: {
    changeLang() {
      this.locale = this.locale === "en" ? "ar" : "en";
      this.$i18n.locale = this.locale;
      localStorage.setItem("locale", this.locale);
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
      document.body.style.overflow = this.isMenuVisible ? "hidden" : "";
    },
    hideMenu() {
      this.isMenuVisible = false;
      document.body.style.overflow = "";
    },
    handleScroll() {
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition > this.lastScrollPosition) {
        this.hideMenu();
      }
      this.lastScrollPosition = currentScrollPosition;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.active-link {
  color: red;
}
</style>
