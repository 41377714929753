<template>
  <div class="LandFill mx-auto container pt-2 pb-16">
    <SlidSec :images="images" />
    <div class="pt-2">
      <h3 class="text-xl font-bold py-2">
        {{ $t(`news_page.main_title_${id}`) }}
      </h3>
      <div>
        <p>{{ $t(`news_page.content_news_${id}`) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandFill",
  props: ["id"],
  data() {
    return {
      images: [
        require(`../assets/Images/news/${this.id}/1.jpg`),
        require(`../assets/Images/news/${this.id}/2.jpg`),
      ],
    };
  },
};
</script>

<style scoped></style>
