<template>
  <div class="OurServices bg-white">
    <div class="container mx-auto my-32 overflow-hidden">
      <h2 class="pb-16 text-2xl font-bold text-red-500">
        {{ $t("company_services") }}
      </h2>
      <div class="w-full h-fit relative overflow-auto" ref="scrollContainer">
        <div class="flex boxs md:w-[180rem] w-[140rem]">
          <router-link
            v-for="index in serve"
            :key="index"
            :to="{ name: 'ServiceDetail', params: { id: index } }"
            class="md:max-w-sm max-w-[300px] mx-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700"
          >
            <img
              class="rounded-t-lg"
              :src="require(`../assets/Images/service/${index}.jpg`)"
              alt=""
            />
            <div class="md:p-5 p-2">
              <div>
                <h5
                  class="mb-2 text-2xl font-bold tracking-tight text-red-500 dark:text-white"
                >
                  {{ $t(`our_service.service_title_${index}`) }}
                </h5>
              </div>
              <p class="mb-6 font-normal text-gray-700 dark:text-gray-400">
                {{ $t(`our_service.service_desc_${index}`) }}
              </p>
            </div>
          </router-link>
        </div>
      </div>
      <div
        class="w-full croser flex rtl:flex-row-reverse justify-start rtl:justify-end px-4 my-2"
      >
        <div @click="scrollLeft" class="cursor-pointer p-5 bg-black mx-2">
          <font-awesome-icon
            class="text-gray-200 icon"
            :icon="['fas', 'chevron-left']"
          />
        </div>
        <div @click="scrollRight" class="cursor-pointer p-5 bg-black">
          <font-awesome-icon
            class="text-gray-200 icon"
            :icon="['fas', 'chevron-right']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServices",
  methods: {
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    },
  },
  data() {
    return {
      show: true,
      serve: [1, 2, 3, 4, 5, 6, 7],
    };
  },
};
</script>

<style scoped>
/* for transition */
.croser > div:hover .icon {
  color: red;
}
/* Add this CSS to customize the scrollbar if needed */
.overflow-auto::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

.overflow-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
