<template>
  <footer class="bg-black dark:bg-gray-900">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
      <div class="md:flex md:flex-row items-center flex-col md:justify-between">
        <div class="mb-6 md:mb-0">
          <router-link to="/" class="flex items-center w-auto justify-center">
            <img src="../assets/mainlogo.png" class="sm:h-10 lg:h-44 me-3" />
            <!-- <img src="../assets/Icon.png" class="h-44 me-3" /> -->
          </router-link>
        </div>
        <div class="grid justify-items-center md:grid-cols-3 grid-cols-1">
          <div
            class="max_md:flex max_md:flex-col max_md:items-center max_md:py-2"
          >
            <h2
              class="mb-6 text-sm font-semibold text-white uppercase dark:text-white"
            >
              {{ $t("footer.importantLinks") }}
            </h2>
            <ul
              class="text-gray-300 dark:text-gray-400 font-medium max_md:flex max_md:flex-col max_md:items-center"
            >
              <li class="mb-4">
                <router-link to="/" class="hover:underline">
                  {{ $t("footer.home") }}
                </router-link>
              </li>
              <li>
                <router-link to="/NewsAndTopic" class="hover:underline">
                  {{ $t("footer.newsAndActivities") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="max_md:py-2">
            <h2
              class="mb-6 text-sm flex md:flex-row items-center flex-col text-white uppercase dark:text-white"
            >
              <font-awesome-icon
                class="px-2 text-2xl text-red-500"
                :icon="['fas', 'location-dot']"
              />
              <div>{{ $t("footer.location") }}</div>
            </h2>
            <ul
              class="text-gray-300 dark:text-gray-400 font-medium max_md:py-2"
            >
              <li class="mb-4"></li>
              <li class="flex md:flex-row items-center flex-col">
                <font-awesome-icon
                  class="px-2 text-2xl text-red-500"
                  :icon="['fa', 'clock']"
                />
                <p class="w-60">{{ $t("footer.workingHours") }}</p>
              </li>
            </ul>
          </div>
          <div>
            <ul
              class="text-gray-300 dark:text-gray-400 font-medium flex items-center md:flex-row flex-col"
            >
              <font-awesome-icon
                class="px-2 text-2xl text-red-500"
                :icon="['fas', 'phone']"
              />
              <div class="max_md:py-2">
                <li class="mb-4">{{ $t("footer.phoneNumber1") }}</li>
                <li class="mb-4">{{ $t("footer.phoneNumber2") }}</li>
                <li class="mb-4">{{ $t("footer.phoneNumber3") }}</li>
                <li class="mb-4">{{ $t("footer.phoneNumber4") }}</li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <hr
        class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"
      />
      <div class="sm:flex sm:items-center sm:justify-between max_md:mx-8">
        <span class="text-sm text-gray-300 sm:text-center dark:text-gray-400"
          >{{ $t("footer.copyright") }}
          <router-link :to="$t('footer.companyLink')" class="hover:underline">{{
            $t("footer.companyName")
          }}</router-link></span
        >
      </div>
    </div>
  </footer>
</template>

<script></script>
