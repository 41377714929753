<template lang="">
  <div class="relative h-72 bg-patren-bg bg-left">
    <div class="container h-full items-center flex mx-auto">
      <div class="text-xl flex rtl:flex-row">
        <span
          class="relative ltr:pr-2 ltr:before:absolute ltr:before:h-[90%] ltr:before:w-[1px] ltr:before:bg-black ltr:before:top-0 ltr:before:left-full rtl:after:absolute rtl:after:h-[90%] rtl:after:w-[1px] rtl:after:bg-black rtl:after:top-0 rtl:after:right-full rtl:pl-2"
          ><router-link to="/"> {{ $t("nav.home") }} </router-link></span
        >
        <span class="mx-2">{{ pageText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageText: {
      type: String,
      default: "Learn More",
    },
  },
};
</script>

<style lang=""></style>
