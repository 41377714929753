import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ServiceDetail from "../components/ServiceDetail.vue";
import IsoDetail from "../components/IsoDetail.vue";
import NewsPage from "../components/NewsPage.vue";

const routes = [
  {
    path: "/NewsAndTopic/:id",
    name: "NewsPage",
    component: NewsPage,
    props: true,
  },
  {
    path: "/about/:id",
    name: "ServiceDetail",
    component: ServiceDetail,
    props: true,
  },
  {
    path: "/:id",
    name: "IsoDetail",
    component: IsoDetail,
    props: true,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/NewsAndTopic",
    name: "NewsAndTopic",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsAndTopic.vue"),
  },
  {
    path: "/LandFill",
    name: "LandFill",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/News/LandFill.vue"),
  },
  {
    path: "/Technologies",
    name: "Technologies",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/News/Technologies.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contactView.vue"),
  },
  {
    path: "/QHSEPolicy",
    name: "QHSEPolicy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/QHSEPolicy.vue"),
  },
  // {
  //   path: "/InhalationPolicy",
  //   name: "InhalationPolicy",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/InhalationPolicy.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = "AMAQ";
  next();
});

export default router;
