<template>
  <router-link
    class="mt-2 bg-black px-16 md:px-8 py-2 text-white hover:text-white hover:bg-red-600"
    :to="linker"
  >
    {{ linkText }}
  </router-link>
</template>

<script>
export default {
  name: "MainButtonBlack",
  props: {
    linker: {
      type: String,
      default: "/",
    },
    linkText: {
      type: String,
      default: "Learn More",
    },
  },
};
</script>
