<template>
  <div class="IsoDetail">
    <div class="container mx-auto">
      <div>
        <div class="w-full flex max_md:flex-col justify-around py-32">
          <div
            class="md:w-4/12 h-min transform max_md:pb-16 md:-rotate-6 flex max_md:justify-center justify-between"
          >
            <img class="md:w-80 w-96 h-full drop-shadow-2xl" :src="IsoImage" />
          </div>
          <div class="md:w-6/12">
            <h3
              class="text-2xl md:w-3/5 leading-[3rem] font-extrabold pb-8 underline-offset-[12px] underline decoration-2 decoration-dashed"
            >
              {{ $t(`iso.${id}.title`) }}
            </h3>
            <p class="text-xl">
              {{ $t(`iso.${id}.introduction`) }}
            </p>
            <p class="py-4 text-red-500 font-bold">
              {{ $t(`iso.${id}.commitment`) }}
            </p>
            <ul
              v-for="index in isoStep"
              :key="index"
              class="list-disc list-inside"
            >
              <h4 class="font-semibold text-lg">
                {{ $t(`iso.${id}.section${index}_title`) }}
              </h4>
              <li v-for="item in steps" :key="item">
                {{ $t(`iso.${id}.section${index}_item${item}`) }}
              </li>
            </ul>
            <p class="pt-5">{{ $t(`iso.${id}.conclusion`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "IsoDetail",
  props: ["id"],
  computed: {
    IsoImage() {
      return require(`../assets/ISO/${this.id}.jpg`);
    },
  },
  data() {
    return {
      isoStep: [1, 2, 3, 4, 5, 6],
      steps: [1, 2],
    };
  },
};
</script>
