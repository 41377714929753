<template>
  <div class="home">
    <div
      class="2xl:h-[100vh] xl:h-[110vh] md:h-[100vh] min:h-[1030px] max:h-[2000px] relative"
    >
      <div>
        <div
          class="absolute top-0 h-full md:h-full w-full overflow-hidden bg-gradient-to-tr rtl:bg-gradient-to-tl from-black from-60% to-transparent opacity-75 z-10"
        ></div>
        <div
          class="absolute top-0 h-full md:h-full overflow-hidden w-full -z-10"
        >
          <video
            autoplay
            loop
            muted
            class="h-screen w-full absolute top-0 left-0 object-cover"
          >
            <source src="../assets/BG.mp4" />
          </video>
        </div>
      </div>
      <div
        class="md:absolute relative w-full h-full z-10 lg:px-16 px-4 py-4 mdd:top-[10%] top-0 lg:from-transparent bg-gradient-to-t from-black from-60% to-transparent"
      >
        <div>
          <div class="mb-16">
            <h1
              class="text-white text-4xl lg:w-80 md:w-3/4 px-2 py-2 border-t-4 ltr:border-l-4 rtl:border-r-4 border-red-500"
            >
              {{ $t("company_name") }}
            </h1>
            <p class="text-white lg:w-2/6 md:w-3/4 my-8">
              {{ $t("company_description") }}
            </p>
            <MainButton
              :linker="linker"
              :linkText="$t('services')"
            ></MainButton>
          </div>
          <div
            class="grid grid-cols-1 grid-rows-2 lg:grid-cols-2 gap-4 xl:w-4/6 lg:w-5/6 w-full"
          >
            <div class="my-6 flex md:flex-row flex-col items-center">
              <font-awesome-icon
                class="text-white font-extrabold text-2xl mb-4 px-4 py-4 border-red-500 border rounded-full md:mx-2"
                :icon="['fab', 'envira']"
              />
              <p class="text-white">
                {{ $t("service_1") }}
              </p>
            </div>
            <div class="my-6 flex md:flex-row flex-col items-center">
              <font-awesome-icon
                class="text-white font-extrabold text-2xl mb-4 px-4 py-[18px] border-red-500 border rounded-full md:mx-2"
                :icon="['fa', 'envelope']"
              />
              <p class="text-white">
                {{ $t("service_2") }}
              </p>
            </div>
            <div class="my-6 flex md:flex-row flex-col items-center">
              <font-awesome-icon
                class="text-white font-extrabold text-2xl mb-4 px-4 py-4 border-red-500 border rounded-full md:mx-2"
                :icon="['fa', 'eye']"
              />
              <p class="text-white">
                {{ $t("service_3") }}
              </p>
            </div>
            <div class="my-6 flex md:flex-row flex-col items-center">
              <font-awesome-icon
                class="text-white font-extrabold text-2xl mb-4 px-4 py-4 border-red-500 border rounded-full md:mx-2"
                :icon="['fa', 'bullseye']"
              />
              <p class="text-white">
                {{ $t("service_4") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- new section -->
    <div class="bg-gray-300 py-4 block">
      <div class="container mx-auto">
        <div
          class="grid md:grid-cols-3 md:grid-rows-1 grid-cols-1 grid-rows-3 md:divide-x md:divide-y-0 divide-y gap-4"
        >
          <div class="px-2 py-6">
            <router-link :to="{ name: 'NewsPage', params: { id: 2 } }">
              <h5 class="text-red-600">{{ $t("read_more") }}</h5>
              <p class="text-sm text-gray-700">March,22,2024</p>
              <p>{{ $t("landfill_title") }}</p>
            </router-link>
          </div>
          <div class="px-2 py-6">
            <router-link :to="{ name: 'NewsPage', params: { id: 7 } }">
              <h5 class="text-red-600">{{ $t("read_more") }}</h5>
              <!-- <p class="text-sm text-gray-700">March,22,2024</p> -->
              {{ $t(`news_page.main_title_7`) }}
            </router-link>
          </div>
          <div class="px-2 py-6">
            <router-link :to="{ name: 'NewsPage', params: { id: 6 } }">
              <h5 class="text-red-600">{{ $t("read_more") }}</h5>
              <!-- <p class="text-sm text-gray-700">March,22,2024</p> -->
              {{ $t(`news_page.main_title_6`) }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- new section -->
    <div class="bg-black py-16">
      <div
        class="container flex sm:flex-row flex-col justify-start items-center mx-auto px-14"
      >
        <transition class="ltr:hidden" name="fade" mode="out-in">
          <h2 v-if="show" key="main" class="pt-8 text-2xl font-bold text-white">
            مستجدات <span class="text-red-500 font-extrabold">الشركة</span>
          </h2>
          <h2 v-else key="secondary" class="pt-8 text-2xl font-bold text-white">
            أحدث <span class="text-red-500 font-extrabold">الأخبار</span>
          </h2>
        </transition>
        <transition class="rtl:hidden" name="fade" mode="out-in">
          <h2 v-if="show" key="main" class="pt-8 text-2xl text-white">
            {{ $t("what_we_do") }}
            <span class="text-red-500 font-extrabold">Do</span>
          </h2>
          <h2 v-else key="secondary" class="pt-8 text-2xl text-white">
            Latest <span class="text-red-500 font-extrabold">News</span>
          </h2>
        </transition>
      </div>
      <div
        class="container pb-6 grid mx-auto justify-items-center md:grid-cols-2 md:grid-rows-1 grid-rows-2 grid-cols-1"
      >
        <div class="w-5/6 py-8">
          <div>
            <img loading="lazy" src="../assets/Images/news/6/2.jpg" alt="" />
          </div>
          <div class="pt-4">
            <h3 class="text-red-500 font-bold">
              {{ $t(`news_page.news_sub_6`) }}
            </h3>
            <p class="text-white mb-10">
              {{ $t(`news_page.main_title_6`) }}
            </p>
            <MainButton
              class="mt-16"
              :linkText="$t('read_more')"
              :to="{ name: 'NewsPage', params: { id: 6 } }"
            />
          </div>
        </div>
        <div class="w-5/6 py-8">
          <div>
            <img loading="lazy" src="../assets/Images/news/8/1.jpg" alt="" />
          </div>
          <div class="pt-4">
            <h3 class="text-red-500 font-bold">
              {{ $t(`news_page.news_sub_8`) }}
            </h3>
            <p class="text-white mb-10">
              {{ $t(`news_page.main_title_8`) }}
            </p>
            <MainButton
              class="mt-16"
              :linkText="$t('read_more')"
              :to="{ name: 'NewsPage', params: { id: 8 } }"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- start standred  -->
    <!-- <div class="container mx-auto py-10">
      <div>
        <img src="../assets/standred.jpg" alt="" />
      </div>
    </div> -->
    <!-- end standred  -->
    <!-- <div class="grid grid-cols-12 h-32 my-6">
      <div class="col-span-4 bg-gray-400 text-blck">
        <div class="mx-auto px-32">
          تطوير مستدام وخدمات بيئية متقدمة من شركة أعماق الدولية
        </div>
      </div>
      <div class="col-span-8 text-gray-700">
        شركة أعماق الدولية تعتمد على تقنيات متقدمة لمعالجة التلوث النفطي مثل
        المعالجة البيولوجية، الحرارية، الفيزيائية والكيميائية، الكهروكيميائية،
        وإعادة التدوير، كما أنها تلتزم بمراقبة الانبعاثات وحماية البيئة عبر
        تقنيات ذكية ومعامل ثابتة ومتنقلة، مما يعكس تطورها والتزامها بتقديم خدمات
        ممتازة.
      </div>
    </div> -->
    <div class="container py-16 mx-auto bg-iso-bg bg-cover bg-left bg-repeat">
      <h2 class="text-3xl text-black mb-4">{{ $t("headline_1") }}</h2>
      <div class="flex max_md:flex-col items-center justify-between">
        <router-link
          class="md:w-[25rem] w-[18rem] p-4 py-4"
          :to="{ name: 'IsoDetail', params: { id: 45001 } }"
        >
          <img src="../assets/ISO/iso45001.png" alt="" />
        </router-link>
        <router-link
          class="md:w-[25rem] w-[18rem] p-4"
          :to="{ name: 'IsoDetail', params: { id: 14001 } }"
        >
          <img src="../assets/ISO/iso14001.png" alt="" />
        </router-link>
        <router-link
          class="md:w-[25rem] w-[18rem] p-4"
          :to="{ name: 'IsoDetail', params: { id: 9001 } }"
        >
          <img src="../assets/ISO/iso9001.png" alt="" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      linker: "/about",
      show: true,
    };
  },
  created() {
    setInterval(() => {
      this.show = !this.show;
    }, 5000);
  },
};
</script>
<style scoped>
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.5s linear;
}

.fade-leave-to {
  transition: all 0.5s linear;
  opacity: 0;
}
</style>
